f<template>
  <div>
    <b-row>
      <b-card-group class="col-12">
        <b-card>
          <b-form class="addtext-form mt-1" @submit.prevent="addtext" >    
            <b-row>

              <b-col cols="12">

                <b-tabs pills v-model="typeSelected" class="nav-pill-success themetype-tabs">
                  <b-tab active title="Based on Text">
                    <template #title title=""> 
                      <feather-icon icon="FileIcon" />Based on Text
                    </template>            
                    <!-- <b-card-text class="mb-1">
                      <b>You need to add your own text for creating the test.</b>
                    </b-card-text> -->
                  </b-tab>
                  <b-tab title="Based on Subject" @click="clickOnSubjectTab()">
                    <template #title>
                      <feather-icon icon="ApertureIcon" />Based on Subject
                    </template>              
                    <!-- <b-card-text class="mb-1">
                      <b>You only need to specify the topic of the test.</b>
                    </b-card-text> -->
                  </b-tab>
                </b-tabs>
              
              </b-col>

              <b-col cols="12" class="mt-1">
                <b-form-textarea
                  id="textarea-addtext"
                  v-model="textQuiz"
                  placeholder="Paste your text here"
                  rows="12"
                  class="mb-2"
                  autofocus
                  v-on:input="charCount()"
                  v-if="typeSelected == 0"
                />
              </b-col>

              <b-col  md="8"  xl="8" sm="12" xs="12" class="" v-if="typeSelected == 1">
                <b-form-group
                  label="Subject"
                  label-for="themeInput"
                >
                  <b-form-input
                    id="themeInput"
                    placeholder="First Law of thermodynamics"
                    v-model="themeQuiz"
                    :maxlength="512"
                    :minlength="minCharactersTheme"
                    autofocus
                  />
                </b-form-group>
              </b-col>        
              
              <b-col  md="4"  xl="4" sm="12" xs="12" class="language-suggest" v-if="typeSelected == 1">
                <b-form-group
                  label="Language"
                  label-for="languageInput"
                >
                  <vue-autosuggest
                  :suggestions="filteredLanguageOptions"
                  :limit="10"
                  :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'English'}"
                  @input="onLanguageChange"
                  id="languageInput"
                  :get-suggestion-value="getSuggestionValue"
                  v-model="languageQuiz"
                >
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{ suggestion.item }}</span>
                  </template>
                </vue-autosuggest>

                </b-form-group>
              </b-col>  

              <!-- submit and reset -->
              <b-col cols="7">
                
                <b-form-group 
                label="Algoritm"
                label-for="selectalg"
                style="display: inline-block;"
                class="mr-1"
                v-if="typeSelected == 0"
                >
                  <b-form-select id="selectalg" v-model="algoritmSelected" :options="algoritmOptions" @change="changeMaxqEvent()" class="" style="margin-right: 10px;"></b-form-select>
                </b-form-group>
                
                <b-form-group 
                label="Max questions"
                label-for="sb-maxqs"
                style="display: inline-block"
                class="spin-maxquestion"
                >
                  <b-form-spinbutton
                    id="sb-maxqs"
                    v-model="maxQuestions"
                    inline
                    class="mr-1"
                    min="5"
                    :max="getMaxQuestions()"
                    step="5"
                  />
                </b-form-group> 

                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  size="lg"
                >
                  Submit
                </b-button>
              </b-col>
              <b-col cols="5" class="text-right char-count" v-if="typeSelected == 0">
                <p class="" ref="charcount">
                  {{totalCharacters}}/({{minCharacters}}-{{limitCharacters}})
                  <feather-icon
                    icon="Edit3Icon"
                    class=""
                  />            
                </p>
              </b-col>

            </b-row>
          </b-form>
        </b-card>  
      </b-card-group>
    </b-row>

    <b-row>
      <b-col md="8" sm="12" class="mt-2">
        <div v-if="typeSelected == 0">
          <p>
            <feather-icon icon="AlertCircleIcon" />  
            To get a quality test, follow these rules:
          </p>
          <ul>
            <li>Your text must be <b>in English for Socrates</b>. Plato supports other languages too, including <b>French, Italian, German, Spanish, Dutch, Portuguese, Chinese, Japanese,</b> and many others.</li>
            <li>The maximum number of questions that you can generate depends on the length of the text. For example, if you choose 15 questions for 600-character text, the algorithm won't be capable of generating that many questions from a short text.</li>
            <li>Remove unnecessary, meaningless text.</li>
            <li>The text must be more than {{minCharacters}} and less than {{limitCharacters}} characters. If you need to create a test of more than {{limitCharacters}} characters - create several tests.</li>
          </ul>
        </div>  
        <div v-else>
          <p>
            <feather-icon icon="AlertCircleIcon" />  
            To create a quality test, follow these rules:
          </p>
          <ul>
            <li>Enter a <b>very specific topic</b>. So, not about anatomy but about a pubococcygeus muscle, not about physics, but about First Law of thermodynamics, etc.</li>
            <li>Choose the language of test.</li>
            <li>Choose the number of questions.</li>
          </ul>
        </div>         

        <h3 class="mt-2 mb-1">Spent characters</h3>
        <b-row class="match-height">
          <b-col md="6" sm="12" class="mb-1 mb-md-0">
            <b-media no-body>
              <b-media-body class="my-auto">
                <div class="progress-wrapper">
                  <b-card-text class="mb-0">
                    <b>Socrates</b> {{ userData.symbols_free_alg }} / {{ limit_to_str(userData.char_limit_alg_free) }} 
                  </b-card-text>
                  <b-progress
                    v-model="userData.symbols_free_alg"
                    :max="limit_to_int(userData.char_limit_alg_free)"
                    :variant="getVariant(userData.symbols_free_alg, userData.char_limit_alg_free)"
                  />
                </div>
              </b-media-body>
            </b-media>
          </b-col>        
          <b-col md="6" sm="12" class="mb-1 mb-md-0">
            <b-media no-body>
              <b-media-body class="my-auto">
                <div class="progress-wrapper">
                  <b-card-text class="mb-0">
                    <b>Plato</b> {{ userData.symbols_paid_alg }} / {{ limit_to_str(userData.char_limit_alg_paid) }}
                  </b-card-text>
                  <b-progress
                    v-model="userData.symbols_paid_alg"
                    :max="limit_to_int(userData.char_limit_alg_paid)"
                    :variant="getVariant(userData.symbols_paid_alg, userData.char_limit_alg_paid)"
                  />
                </div>
              </b-media-body>
            </b-media>
          </b-col>  
          
        </b-row>
        
      </b-col>
      <b-col md="4" sm="12" class="mt-2">
          <h3 class="mb-1">Sample tests from our users</h3>  
            <b-row class="match-height">
                <b-col md="12" sm="12" class="mb-1">
                  <h4>Medicine</h4>
                  <a href="/quiz/b0acfa6f-5c13-475e-a040-e1a2c2358851">Test about immunology</a>
                </b-col>
                <b-col md="12" sm="12" class="mb-1">
                  <h4>Sociology</h4>
                  <a href="/quiz/edf976f6-c0ea-45ed-82ca-1d998454bc9c">Test about culture</a>
                </b-col>
                <b-col md="12" sm="12" class="mb-1">
                  <h4>Geography</h4>
                  <a href="/quiz/e4756983-92f6-4007-8fb9-9febfb945870">Test about the history of geography</a>
                </b-col>
                <b-col md="12" sm="12" class="mb-1">
                  <h4>Chemistry</h4>
                  <a href="/quiz/0e19f139-d3d1-4ea0-bea9-1c1a41a80fcc">Test about chemical solutions</a>
                </b-col>                                                
            </b-row>
      </b-col>      
    </b-row>    

    <b-modal
      ref="modal-form-limit"
      centered
      title="Maximum character size limit exceeded"
      modal-class="modal-danger"
      ok-only
      ok-title="Ok"
      ok-variant="danger"
    >
      <div class="d-block text-center">
        <p>This text is more than {{limitCharacters}} characters.</p>
        <p>Please add more text to continue</p>        
      </div>
    </b-modal> 

    <b-modal
      ref="modal-form-min"
      centered
      title="Minimum character size limit warning"
      modal-class="modal-danger"
      ok-only
      ok-title="Ok"
      ok-variant="danger"
    >
      <div class="d-block text-center">
        <p>Minimum character size is {{minCharacters}}.</p>
        <p>You can't create test based on so short text</p>        
      </div>
    </b-modal> 

    <b-modal
      ref="modal-form-min-subject"
      centered
      title="Minimum character size limit warning"
      modal-class="modal-danger"
      ok-only
      ok-title="Ok"
      ok-variant="danger"
    >
      <div class="d-block text-center">
        <p>Minimum character size is {{minCharactersTheme}}.</p>
        <p>You can't create test based on so short subject</p>        
      </div>
    </b-modal> 
	

    <b-modal
      ref="modal-form-getpremium"
      centered
      title="Get Premium"
      modal-class="modal-danger"
      hide-footer
    >
      <div class="d-block text-center">
        <p>Free test limit exceeded, please subscribe Premium</p>
      </div>
      <b-button variant="outline-success" class="mt-2" block @click="goToProfile()"><feather-icon icon="AwardIcon" class="mr-50" />Get Premium</b-button>
    </b-modal>  
    <b-modal
      ref="modal-form-getpremium-charlimit"
      centered
      title="Get Premium"
      modal-class="modal-danger"
      hide-footer
    >
      <div class="d-block text-center">
        <p>You have riched characters limit on selected algoritm. Please subscribe Premium</p>
      </div>
      <b-button variant="outline-success" class="mt-2" block @click="goToProfile()"><feather-icon icon="AwardIcon" class="mr-50" />Get Premium</b-button>
    </b-modal>
    <b-modal
      ref="modal-form-only-charlimit"
      centered
      title="Character limit excided"
      modal-class="modal-danger"
      hide-footer
    >
      <div class="d-block text-center">
        <p>You have reached the character limit for this algorithm. Please continue to use Socrates with no limit. We are currently working on adding options to renew Plato characters</p>
      </div>
    </b-modal>                   







        
      
        

        
    </div>
</template>


  
  <script>
  import { BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BCardText, BFormTextarea, BModal, BFormSpinbutton, BFormSelect, BProgress, BCardGroup, BTab, BTabs, BMedia, BMediaBody } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { VueAutosuggest } from 'vue-autosuggest'

  export default {
    components: {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormTextarea, BModal, BFormSpinbutton, BFormSelect, BProgress, BCardGroup, VueAutosuggest, BTab, BTabs, BMedia, BMediaBody },

    data() {
      return {
        userData: JSON.parse(localStorage.getItem('userData')),
        textQuiz: "",
        themeQuiz: "",
        languageQuiz: "",
        totalCharacters: 0,
        limitCharacters: 5000,
        minCharacters: 500,
        minCharactersTheme: 2,
        maxQuestions: 10,

        maxQuestionsLimitFree: 50,
        maxQuestionsLimitPaid: 15,

        algoritmSelected: '1',
        algoritmOptions: [
          { value: '1', text: 'Plato' },
          { value: '0', text: 'Socrates' }          
        ],  

        typeSelected: 0,
        typeOptions: [
          { value: 0, text: 'By text - you need to add your text' },
          { value: 1, text: 'By subject - you only need to specify the topic of the test' }          
        ],  
        // languagesSuggestions : ['English', 'Italian', 'Ukrainian'],
        languagesSuggestions: [{data:['Albanian', 'Amharic', 'Arabic', 'Armenian', 'Azeri', 'Belarusian', 'Bengali', 'Bulgarian', 'Chinese', 'Croatian', 'Czech', 'Danish', 
'Divehi', 'Dutch', 'English', 'Estonian', 'Faroese', 'French', 'Frisian', 'Georgian', 'German', 'Greek', 'Greenlandic', 'Hebrew', 'Hungarian', 
'Icelandic', 'Indonesian', 'Irish', 'Italian', 'Japanese', 'Kazakh', 'Khmer', 'Kinyarwanda', 'Kiswahili', 'Korean', 'Kyrgyz', 
'Lao', 'Latvian', 'Lithuanian', 'Luxembourgish', 'Macedonian', 'Malay', 'Maltese', 'Maori', 'Mohawk', 'Mongolian', 'Nepali', 'Occitan', 
'Pashto', 'Persian', 'Polish', 'Portuguese', 'Romanian', 'Romansh', 'Russian', 'Sami', 'Serbian', 'Setswana', 'Sinhala', 'Slovak', 'Slovenian', 
'Spanish', 'Swedish', 'Syriac', 'Tajik', 'Tamazight', 'Telugu', 'Thai', 'Turkish', 'Turkmen', 'Ukrainian', 'Upper sorbian', 'Urdu', 
'Uzbek', 'Vietnamese', 'Welsh', 'Wolof', 'Yakut', 'Yi', 'Yoruba']}],
        filteredLanguageOptions: []

      }
    },    
    methods: {
      removeNullBytes(str){
        return str.split("").filter(char => char.codePointAt(0)).join("")
      },      
      logout() {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        localStorage.removeItem('userData')
        this.$router.push({ name: 'auth-login' })      
      },


      addtext() {
        // console.log(this.languageQuiz);
        // console.log(this.typeSelected);
        
        
        if (this.typeSelected == 0) {
          if (this.totalCharacters > this.limitCharacters) {
            this.$refs['modal-form-limit'].show();
            return;
          }

          if (this.totalCharacters < this.minCharacters) {
            this.$refs['modal-form-min'].show();
            return;
          }        
        } else {
          if (this.themeQuiz.length < this.minCharactersTheme) {
            this.$refs['modal-form-min-subject'].show();
            return;
          }        

        }
        // if (!this.isInputTextEnglish()) {
        //   this.$refs['modal-form-wrong-language'].show();
        //   return;
        // }    
        
        var txt = '';
        var alg = this.algoritmSelected;
        var lang = '';
        if (this.typeSelected == 0) {
          txt = this.removeNullBytes(this.textQuiz);// - delete null characters
        } else {
          txt = this.removeNullBytes(this.themeQuiz);// - delete null characters
          alg = 1;
          lang = (this.languageQuiz == '') ? 'English' : this.languageQuiz;
          
        }
        
        // console.log(lang);
        
        useJwt.addText(
          this.typeSelected,
          {
          //.replace(/\0.*$/, "") // - delete null characters
          //src_text: this.textQuiz.replace(/\0.*$/, "")
          // src_text: this.textQuiz.replace('/\0.*$/', "")
          src_text: txt, 
          max_questions_limit: this.maxQuestions,
          algoritm: alg,
          text_type: this.typeSelected,
          theme_language: lang
        })
        .then(response => {
          const resData = response.data
          // console.log(response.data)
          this.text=""

          this.$router.replace({ name: 'quizzes-page' })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Your text is added",
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: "Please wait for 20-40 seconds for generation to complete. To check if your test is ready, press Refresh button",
                },
              })
            })

        })
        .catch(error => {
          // this.$refs.loginForm.setErrors(error.data);
          if (error.status == 401) {
            this.logout();
          } 
          else if (error.status == 402) {
            if (error.data['limitStatus']) {
              if (error.data['limitStatus'] == 'questions_limit') {
                this.$refs['modal-form-getpremium'].show();            
              } else {
                if (this.userData.is_premium) {
                  this.$refs['modal-form-only-charlimit'].show();            
                } else {
                  this.$refs['modal-form-getpremium-charlimit'].show();            
                }

              }
              
            } else {
              this.$refs['modal-form-getpremium'].show();            
            }
          } 
          else {
            this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: "Error adding text",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: error.data.email,
                  },
                })   
            }        
        })
      



      },
      charCount: function(){
        this.totalCharacters = this.textQuiz.length;
        if ((this.totalCharacters > this.limitCharacters) || (this.totalCharacters <= this.minCharacters)) {
          this.$refs["charcount"].setAttribute('class', 'text-danger')
        } else {
          this.$refs["charcount"].setAttribute('class', '')
        }
      },
      goToProfile() {
        this.$router.push({ name: 'pages-profile' })  
      },
      
      getMaxQuestions() {
        return this.algoritmSelected == '0' ? this.maxQuestionsLimitFree : this.maxQuestionsLimitPaid; 
      },
      changeMaxqEvent() {
        if (this.maxQuestions > this.getMaxQuestions()) {
          this.maxQuestions = this.getMaxQuestions()  
        }  
      },
      clickOnSubjectTab() {
        this.algoritmSelected = '1';
        this.changeMaxqEvent();
      },
      limit_to_str(lim) {
        return lim > 0 ? lim : '∞'
      },

      limit_to_int(lim) {
        return lim > 0 ? lim : 2147483647
      },      

      getVariant(symbols, limit) {
        if (limit == 0) {
          return 'success'
        } else {
          if ((symbols/limit) > 0.7 ) {
            return 'danger'
          } else
          if ((symbols/limit) > 0.4 ) {
            return 'warning'
          } else return 'success'

        }

      },

      updateNavbarProfile() {
        document.getElementById('nb-username').innerHTML = this.userData.name;
        var ps =  document.getElementById('profile-state').getElementsByClassName('b-avatar-badge')[0];
        if (this.userData.is_premium) {
          ps.classList.remove('badge-secondary');
          ps.classList.add('badge-success');
        } else {
          ps.classList.remove('badge-success');          
          ps.classList.add('badge-secondary');
        }
      },      
      getUserProfile() {
        useJwt.getUserProfile({
          // src_text: this.textQuiz,
        })
        .then(response => {
          const resData = response.data
          // console.log("response.data:")
          // console.log(response.data)
          // this.items = resData
          // Set the initial number of items
          

          this.userData.id = resData[0].id;
          this.userData.name = resData[0].name;
          this.userData.credits = resData[0].credits;
          this.userData.email = resData[0].email;
          this.userData.is_premium = resData[0].is_premium;
          this.userData.subscription_period_end = resData[0].subscription_period_end;
          this.userData.stripe_customer_id = resData[0].stripe_customer_id; 
          this.userData.questions_created = resData[0].questions_created; 

          this.userData.symbols_free_alg = resData[0].symbols_free_alg; 
          this.userData.symbols_paid_alg = resData[0].symbols_paid_alg; 
          this.userData.char_limit_alg_free = resData[0].char_limit_alg_free; 
          this.userData.char_limit_alg_paid = resData[0].char_limit_alg_paid;           
          this.userData.subscription_name = resData[0].subscription_name; 

          localStorage.setItem('userData', JSON.stringify(this.userData))
          // document.getElementById('nb-username').innerHTML =this.userData.name;
          this.updateNavbarProfile();
          // NavBar.userData = this.userData;
          // window.location.reload(true);
          // NavBar.forceReload += 1;
          // store.state.appConfig.globalUserData = userData;
          // console.log(this.$store.appConfig.globalUserData);
          // console.log(store.state.appConfig.isRTL)
        })
        .catch(error => {
          //this.$refs.loginForm.setErrors(error.data);
          // console.log(error)
          if (error.status == 401) {
            this.logout();
          } else {            
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error getting User Profile",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.data.email,
                },
              }) 
          }     
        }) 
        .finally(()=>{ 
          // this.seenTable = true        
        });
      },       
      onLanguageChange(text) {
        if (text === '' || text === undefined) {
          return
        }
        const filteredData = this.languagesSuggestions[0].data.filter(item => item.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, 10);
        this.filteredLanguageOptions = [{
              data: filteredData,
            }]


      },
      getSuggestionValue(suggestion) {
        this.languageQuiz = suggestion && suggestion.item;
      }
    },
    mounted() {
    this.getUserProfile();
  
    },          

  }



  </script>
  
  <style lang="scss">
    @import '@core/scss/vue/libs/vue-autosuggest.scss';
  </style>

  <style>
    .language-suggest .autosuggest__results ul li {
      cursor: pointer;
    }
    .themetype-tabs .nav-link {
      color: #28c76f;
    }
    .themetype-tabs .nav-link.active {
      color: #ffffff;
    }    
  
    .nav-pills .nav-link {
      padding: 0.786rem 0.9rem;
    }    

    .themetype-tabs .nav-pills .nav-link svg {
      margin-right: 0.3rem;
    }

  </style>

  